import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { Global } from "@emotion/react";

import globalStyles from "./styles/globalStyles";
import "./styles/variables.css";
import "./styles/fonts.css";
import "./styles/generated/tailwind.css";

import LocaleContext from "../providers/LocaleContext";

import { NavBar, MobileNav, Footer } from "./core";

/**
 * Layout
 */
const Layout = props => {
  const localeContext = useContext(LocaleContext);

  useEffect(() => {
    localeContext.changeLocale(props.locale);
  }, [props.locale]);

  return (
    <div className="relative bg-bg1">
      <Global styles={globalStyles} />
      <MobileNav siteMetaData={props.siteMetaData} alternateNav={props.alternateNav} />
      <NavBar siteMetaData={props.siteMetaData} mainNav={props.mainNav} />
      <main>{props.children}</main>
      <Footer
        siteMetaData={props.siteMetaData}
        alternateNav={props.alternateNav}
        contact={props.contact}
      />
    </div>
  );
};

Layout.propTypes = {
  locale: PropTypes.string,
  siteMetaData: PropTypes.object,
  mainNav: PropTypes.object,
  alternateNav: PropTypes.object,
  contact: PropTypes.object,
  children: PropTypes.node.isRequired,
  navBarTheme: PropTypes.oneOf(["light", "dark"]),
};
Layout.defaultProps = {
  navBarTheme: "light",
};

export default Layout;
