import React from "react";
import PropTypes from "prop-types";

import cx from "classnames";
import theme from "../../constants/theme";

import { Wrapper, LocaleLink, Stack } from "../core";
import { logoTrustedSourceDark } from "../../assets/graphics";

import { mockSiteMetaData } from "../../constants/mockData";

/**
 * Footer
 */
const Footer = props => {
  return (
    <footer className="bg-altBg1 py-30 md:py-40">
      <Wrapper isFullHeight={true}>
        <div className="flex flex-col md:flex-row justify-between">
          {company()}
          <div className="grid grid-cols-1 md:grid-cols-2 md:pb-20">
            {menuSectionCompany()}
            {menuSectionSolutions()}
            {menuSectionLocations()}
            {menuSectionPeople()}
          </div>
        </div>

        <div className="flex flex-col md:flex-row justify-between items-center">
          {copyright()}
          {socialButtons()}
        </div>
      </Wrapper>
    </footer>
  );

  // Company
  function company() {
    let logo = logoTrustedSourceDark;

    return (
      <div className="pb-30 md:pb-40">
        <div className="flex flex-col justify-start items-start">
          <LocaleLink to="/">
            <div>
              <img className="w-full h-auto" src={logo} alt={props.contact?.general?.companyName} />
            </div>
          </LocaleLink>
        </div>
      </div>
    );
  }

  // Alternate Nav
  function menuSection(sectionTitle = "", items = []) {
    let linkStyle = cx("text-sm text-altText1_60 h-32", "hover:text-altText1");

    return (
      <div className="flex flex-col md:px-10 lg:w-300">
        <h3 className="font-medium text-sm text-accent3 uppercase tracking-wide_lg h-32">
          {sectionTitle}
        </h3>

        <nav className="flex flex-col">
          {items.map(item => {
            return (
              <LocaleLink key={item.slug.current} className={linkStyle} to={item.slug.current}>
                {item.title.localized}
              </LocaleLink>
            );
          })}
        </nav>
      </div>
    );
  }

  // Locations
  function locationsSection(sectionTitle = "", items = []) {
    return (
      <div className="flex flex-col md:px-10 lg:w-300">
        <h3 className="font-medium text-sm text-accent3 uppercase tracking-wide_lg h-32">
          {sectionTitle}
        </h3>

        <nav className="flex flex-col">
          {items.map(item => {
            const addressChars = item.address.split(",");
            return (
              <div key={item.location} className="pb-20">
                <h4 className="font-medium text-sm text-altText1 pb-4">{item.location}</h4>
                <a
                  className="text-sm text-altText1_60 leading-normal hover:underline"
                  href={item.mapsLink ?? "https://google.com"}>
                  {addressChars.map(char => (
                    <span key={char}>
                      {char}
                      <br></br>
                    </span>
                  ))}
                </a>
              </div>
            );
          })}
        </nav>
      </div>
    );
  }

  // People
  function peopleSection(sectionTitle = "", items = []) {
    return (
      <div className="flex flex-col md:px-10 lg:w-300">
        <h3 className="font-medium text-sm text-accent3 uppercase tracking-wide_lg h-32">
          {sectionTitle}
        </h3>

        <nav className="flex flex-col">
          {items.map(item => {
            const phone = item.phone ? (
              <p className="text-sm text-altText1_60 leading-normal">{item.phone}</p>
            ) : null;
            return (
              <div key={item.title} className="pb-20">
                <h4 className="font-medium text-sm text-altText1 pb-4">
                  {item.name} - {item.title}
                </h4>
                {phone}
                <a
                  className="text-sm text-altText1_60 leading-normal hover:underline"
                  href={`mailto:${item.email}`}>
                  {item.email}
                </a>
              </div>
            );
          })}
        </nav>
      </div>
    );
  }

  function menuSectionCompany() {
    if (props.alternateNav?.menuSections[0] === null) {
      return;
    }
    return (
      <div className="pb-30 md:pb-40">
        {menuSection(
          props.alternateNav?.menuSections[0].sectionTitle.localized,
          props.alternateNav?.menuSections[0].menuItems ?? []
        )}
      </div>
    );
  }

  function menuSectionSolutions() {
    if (props.alternateNav?.menuSections[1] === null) {
      return;
    }
    return (
      <div className="pb-30 md:pb-40">
        {menuSection(
          props.alternateNav?.menuSections[1].sectionTitle.localized,
          props.alternateNav?.menuSections[1].menuItems ?? []
        )}
      </div>
    );
  }

  function menuSectionLocations() {
    return (
      <div className="pb-30 md:pb-40">
        {locationsSection(
          props.contact?.footerSectionTitleLocations?.localized ?? "Locations",
          props.contact?.locations ?? []
        )}
      </div>
    );
  }

  function menuSectionPeople() {
    return (
      <div className="pb-30 md:pb-40">
        {peopleSection(
          props.contact?.footerSectionTitlePeople?.localized ?? "Team",
          props.contact?.people
        )}
      </div>
    );
  }

  // Copyright
  function copyright() {
    return (
      <div className="py-20 order-last md:order-none">
        <p className="text-sm2 md:text-sm text-altText1_60">
          Copyright &copy; {props.contact?.general?.companyNameOfficial} {copyrightPeriod()}
        </p>
      </div>
    );
  }

  function copyrightPeriod() {
    let yearNow = new Date().getFullYear();
    let yearGiven = props.contact?.general?.startYear ?? yearNow;
    return yearNow === yearGiven ? `${yearGiven}` : `${yearGiven} - ${yearNow}`;
  }

  // Social
  function socialButtons() {
    const socialIcons = props.contact?.social ?? [];
    return (
      <div>
        <Stack direction="row" spacing={6}>
          {socialIcons.map(item => {
            return (
              <a key={item.title} href={item.link} target="_blank">
                <div className="w-48 md:w-40 h-48 md:h-40">
                  <img className="w-full h-full" src={item.icon?.asset?.url} alt={item.title} />
                </div>
              </a>
            );
          })}
        </Stack>
      </div>
    );
  }
};

Footer.propTypes = {
  siteMetaData: PropTypes.object,
  alternateNav: PropTypes.object,
  contact: PropTypes.object,
};
Footer.defaultProps = {
  siteMetaData: mockSiteMetaData,
};

export default Footer;
