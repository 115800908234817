import React, { useContext } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { navigate } from "gatsby";

import theme from "../../constants/theme";
import LocaleContext from "../../providers/LocaleContext";
import MenuContext from "../../providers/MenuContext";

import { IconButton } from "../core";
import { logoTrustedSourceLight } from "../../assets/graphics";
import { IconCross } from "../icons/line";

import { mockSiteMetaData } from "../../constants/mockData";

const localizedPath = require("../../helpers/localeHelpers").localizedPath;

/**
 * MobileNav
 */
const MobileNav = props => {
  const localeContext = useContext(LocaleContext);
  const menuContext = useContext(MenuContext);

  return (
    <MenuContext.Consumer>
      {menuContext => {
        let isMenuOpen = menuContext?.isMenuOpen ?? false;

        let menuBaseStyle = "fixed lg:hidden w-full h-full bg-bg1";

        return (
          <nav
            css={{
              overflowY: "auto",
            }}
            className={isMenuOpen ? cx(menuBaseStyle, "block") : cx(menuBaseStyle, "hidden")}
            style={{ zIndex: 1000 }}>
            {mobileNavBar(menuContext)}
            <div className="h-20 bg-transparent" />
            {menuSections(menuContext)}
          </nav>
        );
      }}
    </MenuContext.Consumer>
  );

  function navigateTo(menuContext, route) {
    navigate(route);
    menuContext.toggleMenu();
  }

  function mobileNavBar(menuContext) {
    let logo = logoTrustedSourceLight;
    let icon = <IconCross />;

    return (
      <div className="flex h-60 md:h-76">
        <div className="flex flex-1 items-center h-full px-10 md:px-20">
          <div onClick={() => onClick(`/`)} className="cursor-pointer">
            <img
              className="h-44 md:h-48 lg:h-52"
              src={logo}
              alt={props.siteMetaData?.company?.companyName}
            />
          </div>
        </div>

        <div className="flex justify-end items-center px-10 md:px-20">
          <IconButton
            onClick={() => menuContext.toggleMenu()}
            icon={icon}
            iconColor={theme.colors.altText1}
            frameColor={theme.colors.accent2}
          />
        </div>
      </div>
    );
  }

  function menuSections(menuContext) {
    let items = props.alternateNav?.menuSections ?? [];

    return (
      <div className="grid grid-cols-1 gap-40 pb-80">
        {items.map(section => {
          return menuSection(menuContext, section.sectionTitle.localized, section.menuItems ?? []);
        })}
      </div>
    );
  }

  function onClick(to = "") {
    localeContext.changePath(to);
    navigateTo(menuContext, localizedPath(localeContext.locale, to));
  }

  function menuSection(menuContext, sectionTitle = "", menuItems = []) {
    return (
      <div key={sectionTitle} className="flex flex-col px-20">
        <h3 className="font-medium text-accent3 text-sm2 uppercase tracking-wide_md py-10">
          {sectionTitle}
        </h3>

        <nav className="flex flex-col">
          {menuItems.map(menuItem => {
            return (
              <button
                key={menuItem.title.localized}
                onClick={() => onClick(menuItem.slug.current)}
                className={cx(
                  "text-md4 text-text1 text-left",
                  "h-56",
                  "border-b-one border-gray-300",
                  "hover:text-accent1"
                )}>
                {menuItem.title.localized}
              </button>
            );
          })}
        </nav>
      </div>
    );
  }
};

MobileNav.propTypes = {
  siteMetaData: PropTypes.object,
  alternateNav: PropTypes.object,
};
MobileNav.defaultProps = {
  siteMetaData: mockSiteMetaData,
};

export default MobileNav;
