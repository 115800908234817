import React, { useContext } from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import theme from "../../constants/theme";
import builderConfig from "../../constants/builderConfig";
import LocaleContext from "../../providers/LocaleContext";
import MenuContext from "../../providers/MenuContext";

import { Stack, LocaleLink, LocaleButton, IconButton } from "./";
import { logoTrustedSourceDark } from "../../assets/graphics";
import { IconMenu, IconGlobe } from "../../components/icons/line";
import { GlyphCaretDown } from "../../components/icons/glyph";

import { mockSiteMetaData } from "../../constants/mockData";

const websiteLanguages = builderConfig.languages.website;

/**
 * NavBar
 */
const NavBar = props => {
  const localeContext = useContext(LocaleContext);

  return (
    <MenuContext.Consumer>
      {menuContext => {
        return <nav className="relative">{topBar(menuContext)}</nav>;
      }}
    </MenuContext.Consumer>
  );

  // Sub menu --------------------
  function topBar(menuContext) {
    return (
      <div className="h-60 md:h-76 lg:h-88 bg-altBg1">
        <div className="wrapper h-full">
          <div className="flex h-full px-10 md:px-20 lg:px-30">
            {logoPane()}
            {navPane()}
            {languageButton()}
            {actionsPane()}
            {menuButtonPane(menuContext)}
          </div>
        </div>
      </div>
    );
  }

  // Logo --------------------
  function logoPane() {
    let logo = logoTrustedSourceDark;

    return (
      <div className="flex justify-start items-center h-full">
        <LocaleLink to="/">
          <img className="h-44 md:h-48" src={logo} alt={props.siteMetaData?.company?.companyName} />
        </LocaleLink>
      </div>
    );
  }

  // Nav --------------------
  function navPane() {
    return (
      <div className="hidden lg:flex justify-end items-center flex-1 h-full">{navItems()}</div>
    );
  }

  function navItems() {
    let menuItems = props.mainNav?.menuItems ?? [];
    return menuItems.map(item => {
      let { title, slug } = item.menuItem;
      let subMenuItems = item.subMenuItems;

      return subMenuItems.length > 0
        ? navItemNoLink(title.localized, subMenuItems)
        : navItem(title.localized, slug.current, subMenuItems);
    });
  }

  function navItemNoLink(title, subMenuItems = []) {
    let linkStyle = cx(
      "flex items-center text-md4 text-altText1_70 h-full px-12",
      "hover:text-altText1"
    );
    let icon = <GlyphCaretDown color={theme.colors.altText3} />;

    return (
      <div
        key={title}
        css={{
          cursor: "pointer",

          "&:hover > .submenu": {
            display: "block",
            opacity: 1,
          },
          "&:focus-within > .submenu": {
            display: "block",
            opacity: 1,
          },
        }}
        className={linkStyle}>
        {title}
        {subMenuItems.length > 0 ? <div className="w-4 bg-transparent" /> : null}
        {subMenuItems.length > 0 ? icon : null}
        {subMenuItems.length > 0 ? subMenu(subMenuItems) : null}
      </div>
    );
  }

  function navItem(title, slug, subMenuItems = []) {
    let linkStyle = cx(
      "flex items-center text-md4 text-altText1_70 h-full px-12",
      "hover:text-altText1"
    );
    let icon = <GlyphCaretDown color={theme.colors.accent1} />;

    return (
      <LocaleLink
        key={title}
        css={{
          "&:hover > .submenu": {
            display: "block",
            opacity: 1,
          },
          "&:focus-within > .submenu": {
            display: "block",
            opacity: 1,
          },
        }}
        className={linkStyle}
        activeStyle={{
          color: theme.colors.altText1,
          borderTop: `3px solid transparent`,
          borderBottom: `3px solid ${theme.colors.altText1}`,
        }}
        to={slug}>
        {title}
        {subMenuItems.length > 0 ? <div className="w-2 bg-transparent" /> : null}
        {subMenuItems.length > 0 ? icon : null}
        {subMenuItems.length > 0 ? subMenu(subMenuItems) : null}
      </LocaleLink>
    );
  }

  // Sub menu --------------------
  function subMenu(subMenuItems = []) {
    return (
      <div
        className={cx(
          "submenu",
          "hidden opacity-0 absolute z-10 w-full top-0 left-0 pb-20 md:mt-76 lg:mt-88",
          "bg-altBg1 rounded-b-lg",
          "transition duration-400 ease-in-out"
        )}>
        <div className="flex justify-center items-center w-full">
          <Stack direction="row" spacing={20}>
            {subMenuItems.map(item => {
              let { title, slug, description, thumbnailImage } = item;
              return subMenuItem(
                title.localized,
                thumbnailImage?.asset?.fluid?.src,
                description?.localized,
                slug.current
              );
            })}
          </Stack>
        </div>
      </div>
    );
  }

  function subMenuItem(title, imageUrl, description, route) {
    return (
      <LocaleLink key={route} to={route}>
        <div className="w-200 h-200 p-16 rounded-md hover:bg-gray-800">
          <h2 className="text-md4 text-altText1 leading-none">{title}</h2>
          <div className="h-4 bg-transparent" />
          <p className="text-sm2 text-altText1_60 leading-normal h-100 overflow-hidden">
            {description}
          </p>
        </div>
      </LocaleLink>
    );
  }

  // Language button --------------------
  function languageButton() {
    return (
      <div
        css={{
          cursor: "pointer",

          "&:hover > .submenu": {
            display: "block",
            opacity: 1,
          },
          "&:focus-within > .submenu": {
            display: "block",
            opacity: 1,
          },
          "&:hover > .iconFrame": {
            backgroundColor: theme.colors.accent2_dimmed,
          },
          "&:focus-within > .iconFrame": {
            backgroundColor: theme.colors.accent2_dimmed,
          },
        }}
        className="hidden relative lg:flex justify-center items-center h-full px-20">
        <div className="iconFrame flex justify-center items-center w-48 h-48 rounded-md">
          <IconGlobe color={theme.colors.altText1_60} />
        </div>
        {languageMenu()}
      </div>
    );
  }

  function changeLocale(toLocale) {
    localeContext.changeLocale(toLocale);
  }

  function languageMenu() {
    return (
      <div
        className={cx(
          "submenu",
          "hidden opacity-0 absolute z-10 top-0 left-0 w-220 md:mt-76 lg:mt-88 bg-altBg1 rounded-b-sm",
          "transition duration-400 ease-in-out"
        )}>
        <nav className="flex flex-col px-20 pb-30">
          {websiteLanguages.map(language => {
            return (
              <button
                key={language.title}
                onClick={() => changeLocale(language.locale)}
                className={cx(
                  localeContext.locale === language.locale ? "text-accent2" : "text-altText1_60",
                  "font-medium text-sm text-left",
                  "w-full h-44",
                  "border-b-one border-gray-500",
                  "hover:text-altText1"
                )}>
                {language.title}
              </button>
            );
          })}
        </nav>
      </div>
    );
  }

  // Actions --------------------
  function actionsPane() {
    return (
      <div className="hidden lg:flex justify-end items-center h-full">
        <LocaleButton
          href={`mailto:${props.siteMetaData.contact?.email}`}
          mode={`href`}
          label={props.mainNav?.callToAction?.linkTitle?.localized}
          labelColor={theme.colors.accent3}
          frameColor={theme.colors.altText1}
          frameColorHover={theme.colors.altText1}
          isStretch={false}
        />
      </div>
    );
  }

  // Menu button --------------------
  function menuButtonPane(menuContext) {
    let icon = <IconMenu />;

    return (
      <div className="flex lg:hidden justify-end items-center flex-1 h-full">
        <IconButton
          onClick={() => menuContext.toggleMenu()}
          icon={icon}
          iconColor={theme.colors.bg1}
          frameColor={theme.colors.accent2}
        />
      </div>
    );
  }
};

NavBar.propTypes = {
  mainNav: PropTypes.object,
  siteMetaData: PropTypes.object,
  navBarTheme: PropTypes.oneOf(["light", "dark"]),
};
NavBar.defaultProps = {
  siteMetaData: mockSiteMetaData,
  navBarTheme: "dark",
};

export default NavBar;
